<template>
  <div class="bg-aquila-yellow h-screen absolute top-0 left-0 w-screen">
    <div class="flex h-1/3">
      <div class="w-1/3"></div>
      <div class="w-1/3 mt-32">
        <img
          alt="Vue logo"
          class="mx-auto object-center my-auto"
          src="../assets/logo.png"
        />
      </div>
      <div class="w-1/3"></div>
    </div>

    <div class="flex mt-16">
      <div class="flex-shrink w-3/5"></div>
      <div class="text-center bg-white p-8 m-4 shadow center flex-grow ">
        <form ref="loginForm" class="pt-8" v-on:keyup.enter="login">
          <t-input
            v-model="loginDetails.username"
            placeholder="Username"
            type="text"
            class="m-4 p-3"
          ></t-input>

          <div class="relative w-full">
            <div
              class="absolute inset-y-0 right-2 flex items-center px-4"
              @mousedown="passwordVisible = 'text'"
              @mouseup="passwordVisible = 'password'"
            >
              <font-awesome-icon
                class="text-gray-400  mr-2 cursor-pointer"
                :icon="['fas', 'eye']"
                size="1x"
              />
            </div>
            <t-input
              v-model="loginDetails.password"
              placeholder="Password"
              :type="passwordVisible"
              class="m-4 p-3 "
            >
            </t-input>
          </div>

          <div></div>
          <t-button
            class="mt-4 bg-aquila-blueDark text-white hover:bg-aquila-blueLight"
            @click="login()"
            type="button"
            >Login</t-button
          >
        </form>
      </div>
      <div class="flex-shrink w-3/5"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      loginDetails: {
        username: "",
        password: "",
      },
      passwordVisible: "password",
      loading: false,
    };
  },
  components: {},
  computed: {
    siteLogo() {
      return process.env.VUE_APP_SITE_LOGO || "site_logo.png";
    },
    ...mapState({
      token: (state) => state.account.token,
      error: (state) => state.account.error,
    }),

    token: {
      get() {
        return this.$store.state.account.token;
      },
    },

    error: {
      get() {
        return this.$store.state.account.error;
      },
    },
  },
  methods: {
    ...mapActions({
      fetchToken: "account/fetchToken",
    }),

    ...mapMutations({
      setToken: "account/setToken",
      clearError: "account/clearError",
    }),

    async login() {
      this.loading = true;
      if (
        this.loginDetails.username != "" &&
        this.loginDetails.password != ""
      ) {
        await this.fetchToken(this.loginDetails);
      }
      if (this.token.accessToken != undefined) {
        this.$router.push("/");
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
